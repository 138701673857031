import { LandingSlider } from "@/DummyData";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Slider from "react-slick";
import { Button, Container } from "reactstrap";
import {
  CardH3,
  CardP,
  CoreServicesBottom,
  CoreServicesBtn,
  CoreServicesCards,
  CoreServicesCol,
  CoreServicesMain,
  CoreServicesRow,
  CoreServicesSlider,
  CoreServicesSub,
  CoreServicesTop,
  CoreServicesTopH2,
  CoreServicesTopP,
} from "../../StyledComponents/HomeStyled";
import SliderModal from "../Modal/SliderModal";

export default function CoreServices() {
  const [modalId, setModalId] = useState(null);
  const sliderRef = useRef(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  // ✅ Function to reapply "lazy-loaded" class to arrows
  const addLazyLoadedClass = () => {
    document.querySelectorAll(".slick-next, .slick-prev").forEach((el) => {
      if (!el.classList.contains("lazy-loaded")) {
        el.classList.add("lazy-loaded");
      }
    });
  };

  // ✅ Detect user interaction
  useEffect(() => {
    const enableNavigation = () => {
      setHasInteracted(true);
      removeEventListeners();
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", enableNavigation);
      document.removeEventListener("scroll", enableNavigation);
      document.removeEventListener("keydown", enableNavigation);
    };

    document.addEventListener("mousemove", enableNavigation);
    document.addEventListener("scroll", enableNavigation);
    document.addEventListener("keydown", enableNavigation);

    return removeEventListeners;
  }, []);

  // ✅ Slider settings (conditionally enable arrows)
  const settings1 = {
    className: "slider center our-services",
    dots: false,
    arrows: hasInteracted, // 👈 Only enable arrows after interaction
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: () => {
      setTimeout(addLazyLoadedClass, 50); // Ensures lazy-load class stays after slide change
    },
    responsive: [
      { breakpoint: 1440, settings: { slidesToShow: 3.5, slidesToScroll: 1, infinite: false, dots: false } },
      { breakpoint: 1370, settings: { slidesToShow: 2.5, slidesToScroll: 1, infinite: false, dots: false } },
      { breakpoint: 1025, settings: { slidesToShow: 2.5, slidesToScroll: 1, infinite: false, dots: false } },
      { breakpoint: 820, settings: { slidesToShow: 2.1, slidesToScroll: 1 } },
      { breakpoint: 736, settings: { slidesToShow: 1.3, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1, initialSlide: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 375, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
// ✅ Function to toggle modal
const toggle = (id) => {
  setModalId(id);
};
  return (
    <CoreServicesMain className="lights !mt-0">
      <Container>
        <CoreServicesSub>
          <CoreServicesTop>
            <CoreServicesRow>
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
                <CoreServicesCol>
                  <CoreServicesTopP>Our Services</CoreServicesTopP>
                  <CoreServicesTopH2>
                    Discover how we can elevate your business to the next level of success!
                  </CoreServicesTopH2>
                </CoreServicesCol>
              </AnimationOnScroll>
              <CoreServicesBtn>
                <Link href="/lets-talk">
                  <Button className="bg-black dark-btn common-btn-hover">Let's Talk</Button>
                </Link>
              </CoreServicesBtn>
            </CoreServicesRow>
          </CoreServicesTop>

          <CoreServicesBottom className="CoreServicesBottom container our-services lazy-loaded">
            <Slider ref={sliderRef} {...settings1}>
              {LandingSlider.map((d) => (
                <CoreServicesSlider key={d.id}>
                  <CoreServicesCards>
                    <Image
                      src={d.img}
                      width={42}
                      height={42}
                      alt={d.name}
                      className="dark-img max-h-[40px]"
                      loading="lazy"
                    />
                    <CardH3>{d.name}</CardH3>
                    <CardP>{d.content}</CardP>
                    <Image
                      onClick={() => toggle(d.id)}
                      src="../svgs/PluseRound.svg"
                      width={25}
                      height={25}
                      alt={d.name}
                      className="dark-img cursor-pointer absolute right-[1rem] bottom-[1rem] animate-pulse"
                      loading="lazy"
                    />
                  </CoreServicesCards>
                </CoreServicesSlider>
              ))}
            </Slider>
          </CoreServicesBottom>
        </CoreServicesSub>
      </Container>

      {modalId && <SliderModal modalId={modalId} toggle={() => setModalId(null)} />}
    </CoreServicesMain>
  );
}
