import { CloseIconModal, InnerButtonsModal, ModalHeadingSlider, ModalInnerItems, ModalName, ModalSliderMain, ModalSubInner } from '../../StyledComponents/LandingStyled';
import { Icon } from '@iconify/react';
import Link from 'next/link';
import { Modal, ModalBody } from 'reactstrap';


function SliderModal({ modalId, toggle }) {
  const HomeSliderData = [
    {
      id: "1",
      Title:"Web Application Development",
      contant: "Our web application development solutions enhance user experience and align with your business strategies for long-term success. We specialize in secure, scalable web apps tailored to meet your business objectives.",
      pagelink:"/web-application-development-company"
    },
    {
      id: "2",
      Title:"Mobile Application Development",
      contant: "Our mobile application development services create secure, user-friendly apps that align with your business goals. We deliver cross-platform solutions that drive engagement and performance.",
      pagelink:"/mobile-app-development-company"
    },
    {
      id: "3",
      Title:"Software Development Services",
      contant: "Our software development services deliver custom solutions that align with your business goals, ensuring efficiency and growth. We provide robust, scalable software to streamline your operations.",
      pagelink:"/software-development"
    },
    {
      id: "4",
      Title:"Website Development Services",
      contant: "Our website development services create secure, tailored solutions that align with your business goals for lasting success. We ensure responsive, SEO-friendly websites for maximum online visibility.",
      pagelink:"/website-design-and-development"
    },
    {
      id: "5",
      Title:"Front-end Development Services",
      contant: "Our front-end development services create intuitive, engaging user interfaces that align with your business goals for optimal user experience. We use modern frameworks to deliver high-performance UI designs.",
      pagelink:"/front-end-development"
    },
    {
      id: "6",
      Title:"Back-end Development Services",
      contant: "Our backend development services deliver robust, scalable solutions that align with your business objectives effectively. We ensure seamless integration and high-end security for your systems.",
      pagelink:"/backend-development-services"
    },
    {
      id: "7",
      Title:"E-Commerce Store Development",
      contant: "Our eCommerce store development services create secure, customized solutions that drive sales and enhance user experience. We deliver Magento, Shopify, and custom eCommerce platforms to boost growth.",
      pagelink:"/ecommerce-development-services"
    },
    {
      id: "8",
      Title:"UI/UX Development Services",
      contant: "Our UI/UX development services create engaging, user-centered designs that enhance usability and align with your business goals. We focus on aesthetics and functionality to elevate user satisfaction.",
      pagelink:"/ui-ux-design-services"
    },
    {
      id: "9",
      Title:"Hire a Developer",
      contant: "Our developer hiring services connect you with skilled professionals who align with your project needs and goals. Hire dedicated experts for web, mobile, or software development success.",
      pagelink:"/hire-dedicated-developers"
    },
    // {
    //   id: "10",
    //   Title:"IT Services",
    //   contant: "Our team of IT professionals is everything you need to make your business digitally smart and successful!",
    //   pagelink:"/"
    // }
  ]

  //console.log(modalId)

  return (
    <div className="blurbg">
      <Modal isOpen={!!modalId} toggle={toggle} centered size='lg'>
        <ModalBody>
        {HomeSliderData.filter((d) => d.id === modalId).map((d) => (
          <ModalSliderMain key={d.id}>
            <ModalHeadingSlider>
              <CloseIconModal className='closebtn' onClick={toggle}><Icon icon="pajamas:close" height={20} width={20} /></CloseIconModal>
            </ModalHeadingSlider>
            {/* <ModalName >Customise Your Development</ModalName> */}
            <ModalInnerItems >{d.Title}</ModalInnerItems>
              <ModalSubInner>{d.contant}</ModalSubInner>
            <InnerButtonsModal><Link href={d.pagelink}>Learn More</Link></InnerButtonsModal>
          </ModalSliderMain>
        ))}
        </ModalBody>
      </Modal>
    </div>
  )
}

export default SliderModal
